/* 用户协议 */
<template>
  <div class="privacy-policy">
    <el-row>
      <el-col :span="24">
        <div class="grid-content"><h2 style="text-align: center;">
          <strong>新致开源</strong><strong v-if="localStorageType==1">医疗</strong><strong
            v-else-if="localStorageType==2">企业</strong><strong>网站使用条款</strong></h2>
          <p style="text-align: right;">更新时间：2024年4月3日</p></div>
      </el-col>
    </el-row>
    <el-card class="rounded-card">
      <p>感谢用户使用新致
        <template v-if="localStorageType==1">&ldquo;开源医疗&rdquo;网站（openhis.org.cn</template>
        <template v-if="localStorageType==2">&ldquo;开源企业&rdquo;网站（opencom.com.cn</template>，下称&ldquo;本网站&rdquo;）。本网站由武汉新致新源软件有限公司（以下简称&ldquo;新致新源&rdquo;或&ldquo;本公司&rdquo;）提供服务和运营。
      </p>
      <p>在使用本网站前，敬请您仔细阅读以下各项使用条款（下称&ldquo;本使用条款&rdquo;）。您对本网站的使用（包括但不限于对本网站的访问、登录，对本网站内容的浏览和使用），将被视为您自愿承诺接受本声明的约束。如果您对本使用条款的内容不能接受，您应当立即停止使用本网站并迅速离开。</p>
      <p><strong><strong>1、本网站的使用</strong></strong></p>
      <p>新致新源有限许可您在您的计算机设备上浏览（使用）本网站展示的内容（由本使用条款第2条规定）。</p>
      <p><strong><strong>2、知识产权声明</strong></strong></p>
      <p>本网站，包括(但不仅限于)&nbsp;文字，内容，软件，录像，音乐，声音，图形，照片，图表，美术设计，图片，名称，标识，商标和/或服务标志（包括已注册和未注册的），以及其他资料（以下简称网站内容）都受到版权法，商标法和一切知识产权公约的保护。本网站内容包括新致新源所有或控制下的内容和第三方所有或控制下，并授权新致新源使用的内容。所有代码，文章，讨论等一切构成本网站的元素都可能是受版权保护的作品。您同意遵守所有适用本网站的版权保护法律法规，以及所有本网站包含的补充性的版权说明或限制。本网站的内容均由相应的机构/个人上传、维护。对于本站内容的任何使用请遵守内容所附带的授权协议。如不清楚相应的授权协议请询问上传该内容的机构/个人。</p>
      <p>用户在开源社区发布的内容的版权均归发布者所有，发布者承担所有被发布内容的版权责任。新致新源有权在其拥有的网站上，展示发布者发布到本网站公共区域的内容。</p>
      <p> <strong><strong>3、内容发布规范</strong></strong></p>
      <p>用户不得以任何方式在本网站上发布、传播下列内容：（1）危害国家安全、泄露国家秘密、损害国家荣誉和利益的；（2）破坏民族团结的，破坏国家宗教政策的；（3）散布谣言、扰乱社会公共秩序的；（4）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；（5）侮辱或者诽谤他人，侵害他人合法权益的；（6）违背伦理道德和公序良俗的；（7）含有法律、行政法规禁止的其他内容的信息；（8）含有骚扰、垃圾广告、恶意信息、诱骗信息的；（9）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；（10）含有其他干扰网站正常运营和侵犯其他用户或第三方合法权益内容的信息。</p>
      <p>本网站保留随时在不事先通知的情况下因任何合理理由而酌情删除或禁用内容的权利。情节严重的，本网站有权停止用户账号内容发布权限。</p>
      <p><strong><strong>4、个人信息的保护</strong></strong></p>
      <p>新致新源尊重访问本网站的任何个人的隐私信息。当您访问本网站的时候可能被要求提供您个人的基本资料（如姓名、电子邮件、电话号码等），您可以自行选择是否提供。对于您提供的个人信息，新致新源将根据中华人民共和国相关法律进行保密并严格保管，不会将这些信息以任何方式提供或展示给任何第三方，但下述情况除外：</p>
      <p>（1）当司法机关或行政机关依照法定程序和法定职权要求本网站披露个人资料时，我们将依法提供相关资料。对于此情况下的任何披露，本网站均得免责；</p>
      <p>（2）对于任何由黑客攻击、计算机病毒侵入或发作、或政府管制而造成的暂时性关闭等影响网站正常运营的不可抗力事件所造成的个人资料的泄露、丢失、被盗用或被篡改等，本网站均得免责；</p>
      <p>（3）对于用户将个人密码告知他人或与他人共享注册帐号所导致的任何个人资料泄露，丢失、被盗用或被篡改等,本网站不负任何责任；</p>
      <p>（4）对于与本网站链接的任何其它网站的个人资料泄露，丢失、被盗用或被篡改等事件，本网站不负任何责任。</p>
      <p><strong><strong>5、网站的更新与变更</strong></strong></p>
      <p>新致新源提供的本网站上的内容仅为方便您获取信息，新致新源有权单方面在任何时间，对本网站的内容进行变更或更新，此种变更或更新无需以通知您或任何第三方为前提。您承认并接受上述变更或更新。我们建议您定期访问本网站以尽快获知有关的更新或变更的信息。</p>
      <p><strong><strong>6、无担保声明</strong></strong></p>
      <p>在法律允许的最大限度内，本网站及本网站的内容不包括任何明确或暗示的有关（但不限于）所有权，适销性，质量满意程度，特定用途的适用性，准确性、时效性和完整性，不侵犯知识产权和其它方权利的承诺。</p>
      <p>新致新源尽其合理的商业努力维护本网站的安全和功能，但不保证本网站上的所有功能正常运作，也不保证本网站连续性地或无故障运行或缺陷被及时更正。</p>
      <p>新致新源不保证本网站系统适合您的电脑操作系统性能，也不保证本网站或其服务器永不产生故障或没有病毒、木马程序和其他有害程序。新致新源亦不承担您因上述问题而产生的任何电脑的损坏的责任。</p>
      <p>新致新源不为您所使用的访问本网站的网络线路和设备的可靠性以及连续性承担责任。</p>
      <p><strong><strong>7、终止使用</strong></strong></p>
      <p>如果您的帐号违反当地相关法律法规或本使用条款，在未经事先通知的情况下，新致新源有权自行判断并立即终止您对本网站的使用，或者采取措施禁止您对本网站的再次访问。</p>
      <p><strong><strong>8、准据法和管辖权</strong></strong></p>
      <p>本网站内容及本使用条款的解释和适用均适用中华人民共和国法律，由于使用本网站而产生的一切纠纷，除另有约定，均由中国上海市浦东新区人民法院管辖。</p>
      <p><strong><strong>9、继续有效原则</strong></strong></p>
      <p>如果本使用条款中的部分条款被有关机关认定为无效，则此无效部分并不影响本使用条款其他部分的效力，其他部分仍然有效。</p>
    </el-card>
  </div>
</template>
<style scoped lang="scss">
.rounded-card {
  border-radius: 10px; /* 圆角半径，根据需要调整 */
}
</style>
<script>
export default {
  name: "userAgreement",

  created() {
    this.localStorageType = localStorage.getItem("type") == null ? 2 : localStorage.getItem("type");
  },

};

</script>